import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import classNames from 'classnames';
import { Collapse } from './collapse';

const Wrapper = styled.div`
  background-color: ${(props) => props.theme.backgroundTheme.contrast.backgroundColor};
  border-radius: 5px;
  margin: var(--spacer-s) 0;
  padding: 15px;
  width: 100%;
`;

const Question = styled.h3`
  align-items: center;
  display: flex;
  font-weight: bold;
  gap: var(--spacer);
  line-height: 30px;
  cursor: pointer;
  position: relative;
  user-select: none;
`;

const Answer = styled.div`
  display: none;
  line-height: 29px;
  margin: var(--spacer-s) 0 0 0;
  transition: all 0.5s ease-in-out;

  &.faqDisplayed {
    display: block;
  }
`;

export type FaqProps = {
  question?: string;
  defaultDisplayed?: boolean;
  lock?: boolean;
  children?: any;
};

export const Faq = ({ ...props }: React.PropsWithChildren<FaqProps>) => {
  const { question, children, defaultDisplayed, lock } = props;
  const [displayed, setFaqDisplayed] = useState(defaultDisplayed ?? true);

  useEffect(() => {
    if (!defaultDisplayed) {
      setFaqDisplayed(false);
    }
  }, [defaultDisplayed]);

  const toggleFaq = useCallback(() => {
    if (!lock) {
      setFaqDisplayed(!displayed);
    }
  }, [lock, displayed, setFaqDisplayed]);

  return (
    <Wrapper>
      <Question onClick={toggleFaq} className={classNames({ questionIcon: displayed })}>
        <Collapse opened={displayed} />
        {question}
      </Question>
      <Answer className={classNames({ faqDisplayed: displayed })}>{children}</Answer>
    </Wrapper>
  );
};

export default Faq;
